.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*Inicio*/
.row {
  margin-top: 25px;
  margin-left: 0;
  margin-right: 0;
}

.banner {
  position: relative;
  width: 100%;
  height: 600px;
}

.bannerImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.bannerOverlay {
  position: absolute;
  top: 0;
  /* left: 0;  */
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  /* justifyContent: center; */
  align-items: center;
  font-size: 2em;
  /* textAlign: center; */
  /* paddingLeft: 20rem; */
}

/* .bannerOverlayLogo {
  display: flex;
  justify-content: center;
  padding-top: 7rem;
} */

.bannerOverlayLogo img {
  height: 70px;
}

.homeInfo {
  justify-content: center;
}

.homeInfo img {
	height: 180px;
}

#services h2 {
  text-align: center;
  padding-top: 3rem;
}

#services .row {
  justify-content: center;
}

#services .row div {
  background-color: #282c34;
  color: white;
}

#services .row div:nth-child(odd) {
  padding: 40px 80px;
  padding-right: 0px;
}

#services .row div:nth-child(even) {
  padding: 40px 80px;
  padding-left: 0px;
}

#precios h2 {
  text-align: center;
  padding-top: 3rem;
}

.servicesImage {
  text-align: center;
}

.servicesImage img {
  height: 300px;
}

#equipo h2 {
  text-align: center;
  padding-top: 3rem;
}

#contact {
  margin-bottom: 50px;
}

#contact h2 {
  text-align: center;
  padding-top: 3rem;
}

#contact .row {
  justify-content: center;
}

#contact .row .row{
  margin-top: 0px;
}

.infoForm div {
  margin-bottom: 10px;
}

.infoContacto {
  padding: 40px;
}

.infoContactoMap {
 height: 100%;
}

.infoContactoDatos {
  display: grid;
  grid-template-columns: max-content 1fr; /* La primera columna se ajusta al contenido más grande, la segunda columna ocupa el espacio restante */
  align-self: start;
}

.infoContactoDatos .row {
  display: contents; /* Hace que cada fila dentro del contenedor sea tratada como si fuera un conjunto de celdas del grid */
  margin-top: 0px;
}
/* 
.infoContacto p {
  margin: 0px;
} */
